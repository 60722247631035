import React, { useEffect } from "react";
import Callaxios from "../Component.js/Callaxios";
import { Link, useParams } from "react-router-dom";
import Header2 from "../NewDesignPages/Header2";
import Footer2 from "../NewDesignPages/Footer2";

export default function Failure() {
  const { order_id } = useParams();
  useEffect(() => {
    fail();
  }, []);
  const fail = async () => {
    try {
      let data = await Callaxios("delete", "order/orders/", { id: order_id });
      if (data.data.Status === 200) {
        console.log("deleted");
      }
    } catch (error) {}
  };
  return (
    <div>
      <Header2 />
      <div>
        <div className="wrapper bg-light">
          <div className="container mt-19 mb-13">
            <div className="row padd text-center">
              <h1>Payment Failed!</h1>
              <h4>Your order was not completed. Please try again.</h4>
              <div className="mt-4">
                <Link
                  to="/cart"
                  className="btn btn-primary rounded"
                  style={{
                    backgroundColor: "#3e4192",
                    borderColor: "#3e4192",
                    fontWeight: 500,
                    width: "110px",
                  }}
                >
                  Try Again
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </div>
  );
}
