import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Callaxios from "../Component.js/Callaxios";
import Header2 from "../NewDesignPages/Header2";
import Footer2 from "../NewDesignPages/Footer2";

export default function Success() {
  const { id, order_id } = useParams();
  const [cartdata, setcartdata] = useState([]);
  useEffect(() => {
    changestatus();
  }, []);
  const changestatus = async () => {
    try {
      let data = await Callaxios("patch", "order/orders/", {
        id: order_id,
        paidstatus: true,
      });
      if (data.data.Status === 200) {
        if (window.localStorage.getItem("ffcart")) {
          window.localStorage.removeItem("ffcart");
        } else {
          let ids = [];
          cartdata.forEach((element) => {
            ids.push(element.id);
          });
          deletecart(ids);
        }
      }
    } catch (error) {}
  };
  const GetCart = () => {
    let cart_list;
    if (window.localStorage.getItem("ffcart")) {
      cart_list = window.localStorage.getItem("ffcart");
      if (cart_list.length) {
        cart_list = JSON.parse(cart_list);
        if (cart_list.length) {
          cart_list.forEach((element) => {
            if (element.frame) {
              element["frameid"] = element.frame.id;
            }
            if (element.product) {
              element["productid"] = element.product[0].id;
            }
          });
        }
        setcartdata(cart_list);
      }
    } else {
      Getcartdata();
    }
  };
  const Getcartdata = async () => {
    try {
      let data = await Callaxios("get", "order/cart/");
      if (data.status === 200) {
        if (data.data.length) {
          let cart = data.data;
          cart.forEach((element) => {
            if (element.image_url) {
              let image = [];
              element.image_url.split(",").forEach((url) => {
                image.push(url);
              });
              element["image_url"] = image;
            }
          });
          setcartdata([...cart]);
        }
      }
    } catch {}
  };
  const deletecart = async (id) => {
    try {
      let data = await Callaxios(
        "delete",
        "order/cart/",
        { id: JSON.stringify(id) },
        "token"
      );
      if (data.data.Status === 200) {
      }
    } catch (error) {}
  };

  return (
    <div>
      <Header2 />
      <div className="wrapper bg-light">
        <div className="container mt-20 mb-13">
          <div className="row padd text-center">
            <h1>Thank you!</h1>
            <h4>Your order has been successfully placed.</h4>
            <div className="mt-4">
              <Link
                to="/frames"
                className="btn btn-primary rounded"
                style={{
                  backgroundColor: "#3e4192",
                  borderColor: "#3e4192",
                  fontWeight: 500,
                  width: "110px",
                }}
              >
                Continue
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </div>
  );
}
