import React, { useEffect, useState } from "react";
import Callaxios from "./Callaxios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BaseUrl } from "./Url";

export default function Checkout() {
  const [cartdata, setcartdata] = useState([]);
  const [delivery, setdelivery] = useState("");
  const [citydata, setcitydata] = useState([]);
  const [customername, setcustomername] = useState("");
  const [email, setemail] = useState("");
  const [contact, setcontact] = useState("");
  const [place, setplace] = useState("");
  const [address, setaddress] = useState("");
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [firstname, setfirstname] = useState("");
  const [signusername, setsignusername] = useState("");
  const [signpassword, setsignpassword] = useState("");
  const [signrepassword, setsignrepassword] = useState("");
  const [modal2, setmodal2] = useState(false);
  const [modal1, setmodal1] = useState(false);
  const [load, setload] = useState(false);
  const [forgotpass, setforgotpass] = useState(false);
  const [loading, setloading] = useState({ forgot: false, login: false });
  const [postAfterModalClose, setpostAfterModalClose] = useState(false);
  const phonePattern = /^\+971-[2-9]\d{2}-\d{3}-\d{4}$/;
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  useEffect(() => {
    GetCart();
    Getshipping();
    Getcity();
    window.scrollTo(0, 0);
  }, []);
  const notify = (msg) =>
    toast.success(msg, {
      position: "top-left",
      theme: "dark",
    });
  const notifyerror = (msg) =>
    toast.error(msg, {
      position: "top-left",
      theme: "dark",
    });

  const GetCart = () => {
    let cart_list;
    if (window.localStorage.getItem("ffcart")) {
      cart_list = window.localStorage.getItem("ffcart");
      if (cart_list.length) {
        cart_list = JSON.parse(cart_list);
        if (cart_list.length) {
          cart_list.forEach((element) => {
            if (element.frame) {
              element["frameid"] = element.frame.id;
            }
            if (element.product) {
              element["productid"] = element.product[0].id;
            }
          });
        }
        setcartdata(cart_list);
      }
    } else {
      Getcartdata();
    }
  };
  const Getcartdata = async () => {
    try {
      let data = await Callaxios("get", "order/cart/");
      if (data.status === 200) {
        if (data.data.length) {
          let cart = data.data;
          cart.forEach((element) => {
            if (element.image_url) {
              let image = [];
              element.image_url.split(",").forEach((url) => {
                image.push(url);
              });
              element["image_url"] = image;
            }
          });
          setcartdata([...cart]);
        }
      }
    } catch {}
  };
  const Getshipping = async () => {
    try {
      let data = await Callaxios("get", "product/delivery/");
      if (data.status === 200) {
        setdelivery(data.data[0].delivery_charge);
      }
    } catch (error) {}
  };
  const Getcity = async () => {
    try {
      let data = await Callaxios("get", "frame/city/");
      if (data.status === 200) {
        setcitydata(data.data);
      }
    } catch (error) {
      notifyerror("Something went wrong");
    }
  };

  const postorder = async () => {
    try {
      setload(true);
      if (customername && contact && place && address) {
        const hasToken = window.localStorage.getItem("fotoframe_usertoken");

        if (!hasToken) {
          setmodal1(true);
          setpostAfterModalClose(true);
          setload(false);
          return;
        }

        await proceedWithOrder();
      } else {
        notifyerror("Fill All Billing Address");
        setload(false);
      }
    } catch (error) {
      setload(false);
    }
  };

  const proceedWithOrder = async () => {
    try {
      let body = cartdata.map((element) => {
        return {
          status: "new",
          image_url:
            element?.image_url?.length > 0
              ? element.image_url.join(",")
              : element?.product?.[0]
              ? element?.product?.[0]?.product_image
              : "",
          orientation: element.orientation,
          size: element.size,
          product_type: element.product_name,
          frame_type: element?.frame_look ?? "",
          frame: element.frame ? element.frame.id : null,
          price: element.total_price,
          papper: element.papper,
          productid: Array.isArray(element.productid)
            ? element.productid[0].id
            : typeof element.productid === "object"
            ? element?.productid?.id ?? null
            : element.productid,
          frameid: element.frameid
            ? typeof element.frameid === "object"
              ? element.frameid[0].id
              : element.frameid
            : "",
        };
      });

      let datalist = {
        Customer_name: customername,
        email: email,
        contact: contact,
        address: address,
        city: place,
        total_price:
          cartdata.reduce(
            (n, { total_price }) => n + parseInt(total_price),
            0
          ) + delivery,
        shipping: delivery || 0,
        product_list: body,
        status: "new",
      };

      let data = await Callaxios("post", "order/orders/", datalist, "token");
      if (data.data.Status === 200) {
        Payment_Page(data.data.order_id);
        setbillnull();
        // notify("Order Placed Successfully");
      } else {
        notifyerror("Something went wrong");
      }
    } catch (error) {
      notifyerror("Order placement failed");
    } finally {
      setload(false);
      setpostAfterModalClose(false);
    }
  };

  // Skip button handler in modal
  const handleSkip = () => {
    setmodal1(false);
    setsignnull();
    // proceedWithOrder();
    if (postAfterModalClose) {
      proceedWithOrder();
    }
  };

  const setbillnull = () => {
    setcustomername("");
    setcontact("");
    setemail("");
    setplace("");
    setaddress("");
    // window.localStorage.removeItem("ffcart")
    setcartdata([]);
  };
  const login = async (e) => {
    e.preventDefault();
    try {
      let body = {
        method: "post",
        url: BaseUrl + "user/login/",
        data: { username: username, password: password },
      };
      let data = await axios(body);
      if (data.data.Status === 200) {
        window.localStorage.setItem("fotoframe_usertoken", data.data.token);
        postorder();
      } else {
        notifyerror("Invalid Username or password");
      }
    } catch (error) {
      console.error(error);
      notifyerror("Invalid Username or password");
    }
  };
  const postuser = async (e) => {
    e.preventDefault();
    try {
      if (signpassword === signrepassword) {
        let data = await Callaxios("post", "user/user/", {
          username: signusername,
          first_name: firstname,
          password: signpassword,
        });
        if (data.data.Status === 200) {
          notify("Successfully registered");
          setmodal2(false);
          setmodal1(true);
          setsignnull();
        } else {
          let msg = data.data.Message;

          const start_pos =
            data.data.Message.indexOf("string='") + "string='".length;
          const end_pos = data.data.Message.indexOf("'", start_pos);
          if (start_pos !== -1 && end_pos !== -1) {
            let extractedMessage = data.data.Message.substring(
              start_pos,
              end_pos
            );
            msg = extractedMessage;
          }
          notifyerror(msg);
        }
      } else {
        notifyerror("Password and repassword are different");
      }
    } catch (error) {}
  };
  const setsignnull = () => {
    setusername("");
    setpassword("");
    setsignusername("");
    setsignpassword("");
    setsignrepassword("");
  };
  const deletecart = async (id) => {
    try {
      let data = await Callaxios(
        "delete",
        "order/cart/",
        { id: JSON.stringify(id) },
        "token"
      );
      if (data.data.Status === 200) {
      }
    } catch (error) {}
  };
  const forgotHandler = async (e) => {
    e.preventDefault();
    setloading({ ...loading, forgot: true });
    try {
      let body = {
        method: "post",
        url: BaseUrl + "user/sentmail/",
        data: {
          email: username,
          url: window.location.origin + "/forgotpassword/",
        },
      };
      let data = await axios(body);
      if (data.data.Status === 200) {
        notify("check your mail to change password");
        // window.localStorage.setItem("fotoframe_usertoken",data.data.token)
        // setpromodal1(false)
        // setpromodal2(false)
        setforgotpass(false);
        setsignnull();
        // return navigate("/profile")
      } else {
        notifyerror(data.data.Message);
      }
    } catch (error) {
      console.error(error);
      notifyerror("Invalid Username or password");
    }
    setloading({ ...loading, forgot: false });
  };
  const Payment_Page = (order_id) => {
    setload(true);
    // if(window.localStorage.getItem("ffcart")){
    //   window.localStorage.removeItem("ffcart")
    // }else{
    //   let ids =[]
    //   cartdata.forEach(element=>{
    //     ids.push(element.id)
    //   })
    //   deletecart(ids)
    // }

    var data = {
      product_name: "check_out",
      unit_amount: Math.round(
        cartdata.reduce((n, { total_price }) => n + parseInt(total_price), 0) +
          delivery
      ),
      currency: "AED",
      site: window.origin,
      order_id: order_id,
    };
    axios
      .post(`${BaseUrl}order/create-checkout-session/`, data, {
        headers: {
          Authorization: window.localStorage.getItem("fotoframe_usertoken"),
        },
      })
      .then((res) => {
        if (res.data.Status === 200) {
          setload(true);
          window.location.assign(res.data.Message.url);
          // window.localStorage.removeItem("ffcart");
        } else {
          // setalert({ open : true , msg: "Something Went Wrong",severity:"error"})
          notifyerror("Something Went Wrong");
          setload(false);
        }
      })
      .catch((error) => {
        setload(false);
        fail(order_id);
        if (error.response.request.status === 401) {
          // setalert({ open : true , msg: "Un Authorized request",severity:"error"})
          notifyerror("Un Authorized request");
        } else {
          // setalert({ open : true , msg: "Something Went Wrong",severity:"error"})
          notifyerror("Something Went Wrong");
        }
      });
  };
  const fail = async (order_id) => {
    try {
      let data = await Callaxios("delete", "order/orders/", { id: order_id });
      if (data.data.Status === 200) {
        setmodal1(false);
        setmodal2(false);
        GetCart();
      }
    } catch (error) {}
  };
  return (
    <div>
      <section className="wrapper bg-light">
        <div className="container mt-18 pb-14 pb-md-16">
          <ToastContainer />
          {/* <div>
      <button onClick={()=>Payment_Page(123)}>click</button>
    </div> */}
          {load ? (
            <div className="spinner-containerload">
              <div className="spinner"></div>
            </div>
          ) : null}
          <div className="row gy-10 mx-0 mx-md-4 mx-lg-2">
            <div className="col-lg-8">
              <h3 className="mb-4">Billing Address</h3>
              <form className="needs-validation" noValidate>
                <div className="row g-3">
                  <div className="col-sm-6">
                    <div className="form-floating">
                      <input
                        onChange={(e) =>
                          e.target.value.charAt(0) !== " "
                            ? setcustomername(e.target.value)
                            : ""
                        }
                        value={customername}
                        type="text"
                        className="form-control"
                        id="firstName"
                        placeholder="First name"
                        required
                      />
                      <label htmlFor="firstName" className="form-label">
                        First Name
                      </label>
                      <div className="invalid-feedback">
                        {" "}
                        Valid first name is required.{" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-floating">
                      <input
                        onChange={(e) => setcontact(e.target.value)}
                        value={contact}
                        type="tel"
                        className={`form-control `}
                        id="tel"
                        placeholder="contact"
                        required
                      />
                      <label htmlFor="email" className="form-label">
                        Contact
                      </label>
                      <div className="invalid-feedback">
                        {" "}
                        Please enter a valid contact for shipping updates.{" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-floating">
                      <input
                        onChange={(e) => setemail(e.target.value)}
                        value={email}
                        type="email"
                        className={`form-control ${
                          email
                            ? emailPattern.test(email)
                              ? ""
                              : "is-invalid"
                            : ""
                        }`}
                        id="email"
                        placeholder="you@example.com"
                        required
                      />
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <div className="invalid-feedback">
                        {" "}
                        Please enter a valid email address for shipping updates.{" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-select-wrapper">
                      <select
                        onChange={(e) => setplace(e.target.value)}
                        value={place}
                        className="form-select"
                        id="state"
                        required
                      >
                        <option value="" hidden>
                          Place
                        </option>
                        {citydata
                          ? citydata.map((itm, k) => (
                              <option value={itm.city} key={k}>
                                {itm.city}
                              </option>
                            ))
                          : null}
                      </select>
                      <div className="invalid-feedback">
                        {" "}
                        Please provide a valid place.{" "}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        onChange={(e) => setaddress(e.target.value)}
                        value={address}
                        type="text"
                        className="form-control"
                        id="address"
                        placeholder="1234 Main St"
                        required
                      />
                      <label htmlFor="address" className="form-label">
                        Address
                      </label>
                      <div className="invalid-feedback">
                        {" "}
                        Please enter your shipping address.{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/* /column */}
            {cartdata.length ? (
              <div className="col-lg-4">
                <h3 className="mb-4">
                  Order Summary {"(" + cartdata.length + ")"}
                </h3>
                {/* /.shopping-cart*/}
                <hr className="my-4" />
                <h3 className="mb-2">Shipping</h3>

                <div className="table-responsive">
                  <table className="table table-order">
                    <tbody>
                      <tr>
                        <td className="ps-0">
                          <strong className="text-dark">Subtotal</strong>
                        </td>
                        <td className="pe-0 text-end">
                          <p className="price">
                            {cartdata
                              ? cartdata.reduce(
                                  (n, { total_price }) =>
                                    n + parseInt(total_price),
                                  0
                                )
                              : null}
                            <span className="aedsize"> AED</span>
                          </p>
                        </td>
                      </tr>

                      <tr>
                        <td className="ps-0">
                          <strong className="text-dark">Shipping</strong>
                        </td>
                        <td className="pe-0 text-end">
                          <p className="price">
                            {delivery}
                            <span className="aedsize"> AED</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="ps-0">
                          <strong className="text-dark">Grand Total</strong>
                        </td>
                        <td className="pe-0 text-end">
                          <p className="price text-dark fw-bold">
                            {cartdata
                              ? cartdata.reduce(
                                  (n, { total_price }) =>
                                    n + parseInt(total_price),
                                  0
                                ) + delivery
                              : null}
                            <span className="aedsize"> AED</span>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button
                  onClick={() => postorder()}
                  className="btn btn-primary rounded w-100 mt-4"
                  style={{
                    backgroundColor: "#3e4192",
                    borderColor: "#3e4192",
                    fontWeight: 500,
                  }}
                >
                  Place Order
                </button>
              </div>
            ) : null}
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>

      {/* modal check */}
      <div
        className={modal1 ? "modal fade show spinner-container" : "modal fade"}
        style={modal1 ? { display: "block" } : { display: "none" }}
        id="modal-signin"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content text-center">
            <div className="modal-body">
              <button
                type="button"
                onClick={() => setmodal1(!modal1) && setsignnull()}
                className="btn-close"
              />
              {forgotpass ? (
                <>
                  <h2 className="mb-3 text-start">Forgot password!</h2>
                  <p className="lead mb-6 text-start">Fill your email .</p>
                  <form onSubmit={(e) => forgotHandler(e)} className="mb-3">
                    <div className="form-floating mb-4">
                      <input
                        required
                        onChange={(e) =>
                          !/\s/.test(e.target.value)
                            ? setusername(e.target.value)
                            : ""
                        }
                        value={username}
                        type="emaillogin"
                        className="form-control"
                        placeholder="Email"
                      />
                      <label htmlFor="loginEmail">Email</label>
                    </div>

                    <button
                      type={loading.forgot ? "button" : "submit"}
                      className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                    >
                      {loading.forgot ? "Loading..." : "Sent email"}{" "}
                    </button>
                  </form>
                </>
              ) : (
                <>
                  <h2 className="mb-3 text-start">
                    {modal1 ? "Sign In to Order" : "Welcome Back"}
                  </h2>
                  <p className="lead mb-6 text-start">
                    Fill your email and password to sign in.
                  </p>
                  <form onSubmit={(e) => login(e)} className="mb-3">
                    <div className="form-floating mb-4">
                      <input
                        required
                        onChange={(e) =>
                          !/\s/.test(e.target.value)
                            ? setusername(e.target.value)
                            : ""
                        }
                        value={username}
                        type="emaillogin"
                        className="form-control"
                        placeholder="Email"
                      />
                      <label htmlFor="loginEmail">Email</label>
                    </div>
                    <div className="form-floating  mb-4">
                      <input
                        required
                        onChange={(e) => setpassword(e.target.value)}
                        value={password}
                        type="password"
                        className="form-control"
                        placeholder="Password"
                      />
                      {/* <span className="password-toggle"><i className="uil uil-eye" /></span> */}
                      <label htmlFor="loginPassword">Password</label>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                    >
                      Sign In
                    </button>
                  </form>
                </>
              )}
              {/* /form */}
              {forgotpass ? (
                <p className="mb-1">
                  <a
                    onClick={() => setforgotpass(false)}
                    href="#"
                    className="hover"
                  >
                    Sign in{" "}
                  </a>
                </p>
              ) : (
                <p className="mb-1">
                  <a
                    onClick={() => setforgotpass(true)}
                    href="#"
                    className="hover"
                  >
                    Forgot Password?
                  </a>
                </p>
              )}
              {/* <p className="mb-1"><a href="#" className="hover">Forgot Password?</a></p> */}
              <p className="mb-0">
                {/* Don't have an account?{" "} */}
                <a
                  href="#"
                  onClick={() =>
                    setmodal1(false) & setmodal2(true) & setsignnull()
                  }
                  className="hover mx-2"
                >
                  Sign up
                </a>
                <a href="#" onClick={handleSkip} className="hover mx-2">
                  Skip
                </a>
              </p>

              {/*/.social */}
            </div>
            {/*/.modal-content */}
          </div>
          {/*/.modal-body */}
        </div>
        {/*/.modal-dialog */}
      </div>
      {/*/.modal */}
      <div
        className={modal2 ? "modal fade show spinner-container" : "modal fade"}
        style={modal2 ? { display: "block" } : { display: "none" }}
        id="modal-signup"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content text-center">
            <div className="modal-body">
              <button
                type="button"
                onClick={() => setmodal2(!modal2)}
                className="btn-close"
              />
              <h2 className="mb-3 text-start">Sign up to FotoFrame</h2>
              <p className="lead mb-6 text-start">
                Registration takes less than a minute.
              </p>
              <form onSubmit={(e) => postuser(e)} className="text-start mb-3">
                <div className="form-floating mb-4">
                  <input
                    required
                    onChange={(e) => setfirstname(e.target.value)}
                    value={firstname}
                    type="Firstname"
                    className="form-control"
                    placeholder="Email"
                  />
                  <label htmlFor="">Name</label>
                </div>
                <div className="form-floating mb-4">
                  <input
                    required
                    onChange={(e) => setsignusername(e.target.value)}
                    value={signusername}
                    type="email"
                    className="form-control"
                    placeholder="Email"
                  />
                  <label htmlFor="">Email</label>
                </div>
                <div className="form-floating mb-4">
                  <input
                    required
                    onChange={(e) => setsignpassword(e.target.value)}
                    value={signpassword}
                    type="password"
                    className="form-control"
                    placeholder="Password"
                  />
                  {/* <span className="password-toggle"><i className="uil uil-eye" /></span> */}
                  <label htmlFor="">Password</label>
                </div>
                <div className="form-floating  mb-4">
                  <input
                    required
                    onChange={(e) => setsignrepassword(e.target.value)}
                    value={signrepassword}
                    type="password"
                    className="form-control"
                    placeholder="Confirm Password"
                  />
                  {/* <span className="password-toggle"><i className="uil uil-eye" /></span> */}
                  <label htmlFor="">Confirm Password</label>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                >
                  Sign Up
                </button>
              </form>
              {/* /form */}
              <p className="mb-0">
                {/* Already have an account?{" "} */}
                <a
                  href="#"
                  onClick={() => setmodal2(false) & setmodal1(true)}
                  className="hover mx-2"
                >
                  Sign in
                </a>
                <a href="#" onClick={handleSkip} className="hover mx-2">
                  Skip
                </a>
              </p>

              {/*/.social */}
            </div>
            {/*/.modal-content */}
          </div>
          {/*/.modal-body */}
        </div>
        {/*/.modal-dialog */}
      </div>
    </div>
  );
}
